@font-face {
  font-family: Work Sans;
  src: url("WorkSans-Regular.6700bb84.ttf") format("truetype");
  font-display: swap;
}

html {
  content: normal;
}

body {
  font-optical-sizing: auto;
  margin: 0;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
}

nav[vlx="menu__top_bar"] {
  color: #fff;
  background-color: #333;
  justify-content: space-between;
  align-items: center;
  padding: .5em 1em;
  display: flex;
}

nav[vlx="menu__top_bar"] .announcement p {
  margin: 0;
}

nav[vlx="menu__top_bar"] .top-links a {
  color: #fff;
  margin-left: 1em;
  text-decoration: none;
}

nav[vlx="menu__top_bar"] .top-links a:hover {
  color: #ddd;
}

nav[vlx="menu__top"] {
  background-color: #333;
  justify-content: space-between;
  display: flex;
}

nav[vlx="menu__top"] a[vlx="logo__header"] > img {
  width: 100px;
  height: auto;
  margin: 10px;
}

nav[vlx="menu__top"] menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

nav[vlx="menu__top"] menu li {
  padding: 10px 15px;
}

nav[vlx="menu__top"] menu a {
  color: #fff;
  text-decoration: none;
}

nav[vlx="menu__top"] menu a:hover {
  background-color: #777;
}

[vlx="main"] {
  background-color: #fff;
  width: 100%;
  height: auto;
  min-height: 500px;
}

nav[vlx="menu__bottom"] {
  background-color: #333;
  justify-content: flex-start;
  display: flex;
}

nav[vlx="menu__bottom"] img[vlx="logo__footer"] {
  width: 100px;
  height: auto;
  margin: 10px;
}

nav[vlx="menu__bottom"] .column {
  color: #fff;
  flex: 1;
  padding: 10px;
}

nav[vlx="menu__bottom"] a {
  color: #fff;
  text-decoration: none;
}

nav[vlx="menu__bottom"] a:hover {
  background-color: #777;
}

nav[vlx="menu__bottom_bar"] {
  color: #fff;
  background-color: #333;
  justify-content: space-around;
  padding: 10px;
  display: flex;
}

nav[vlx="menu__bottom_bar"] .column {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

nav[vlx="menu__bottom_bar"] a {
  color: #fff;
  text-decoration: none;
}

nav[vlx="menu__bottom_bar"] a:hover {
  background-color: #777;
}
/*# sourceMappingURL=index.2ac23de3.css.map */
