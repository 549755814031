@font-face {
  font-family: 'Work Sans';
  src: url("../assets/fonts/work_sans/static/WorkSans-Regular.ttf") format('truetype');
  font-display: swap;
}
html {
  content: normal;
}
body {
  font-family: 'Work Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  margin: 0;
}
nav[vlx=menu__top_bar] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: #fff;
  padding: 0.5em 1em;
}
nav[vlx=menu__top_bar] .announcement p {
  margin: 0;
}
nav[vlx=menu__top_bar] .top-links a {
  color: #fff;
  text-decoration: none;
  margin-left: 1em;
}
nav[vlx=menu__top_bar] .top-links a:hover {
  color: #ddd;
}
nav[vlx=menu__top] {
  display: flex;
  justify-content: space-between;
  background-color: #333;
}
nav[vlx=menu__top] a[vlx="logo__header"] > img {
  width: 100px;
  height: auto;
  margin: 10px;
}
nav[vlx=menu__top] menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
nav[vlx=menu__top] menu li {
  padding: 10px 15px;
}
nav[vlx=menu__top] menu a {
  color: #fff;
  text-decoration: none;
}
nav[vlx=menu__top] menu a:hover {
  background-color: #777;
}
[vlx=main] {
  background-color: #fff;
  min-height: 500px;
  width: 100%;
  height: auto;
}
nav[vlx=menu__bottom] {
  display: flex;
  justify-content: flex-start;
  background-color: #333;
}
nav[vlx=menu__bottom] img[vlx=logo__footer] {
  width: 100px;
  height: auto;
  margin: 10px;
}
nav[vlx=menu__bottom] .column {
  flex: 1;
  padding: 10px;
  color: #fff;
}
nav[vlx=menu__bottom] a {
  color: #fff;
  text-decoration: none;
}
nav[vlx=menu__bottom] a:hover {
  background-color: #777;
}
nav[vlx=menu__bottom_bar] {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  padding: 10px;
  color: #fff;
}
nav[vlx=menu__bottom_bar] .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
nav[vlx=menu__bottom_bar] a {
  color: #fff;
  text-decoration: none;
}
nav[vlx=menu__bottom_bar] a:hover {
  background-color: #777;
}
